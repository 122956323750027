<template>
    <div class="full-app" :class="{ active: isActive }">
        <div class="blue-circle corner"></div>
        <div class="gradiant-circle corner"></div>
        <b-row>
            <b-col :cols="12" :md="isActive ? 6 : 12" class="robot-container">
                <div class="robot p-1">
                    <img class="logo" src="/media/logo.svg" alt="Arena" />
                    <div class="face">
                        <LottieAnimation
                            class="lottie"
                            :loop="isLoop"
                            :path="'./media/lottie/smile' + inputAnim + '.json'"
                        />
                    </div>
                    <div>
                        <p class="m-0">
                            زيارة وحدة مابتكفي
                        </p>
                    </div>
                </div>
            </b-col>
            <b-col
                cols
                md="6"
                v-if="isFormActive"
                class="animate__animated animate__jackInTheBox"
            >
                <ValidationObserver ref="observer">
                    <div class="form px-3">
                        <h4 class="mb-3">
                            <strong>قم بتسجيل بياناتك داخل الحقول</strong>
                        </h4>
                        <div class="form-input-group w-100">
                            <EKInputText
                                v-model="userDto.name"
                                placeholder="الاسم الكامل (إختياري)"
                                name="fullname"
                                @focus="
                                    setFace(13);
                                    isLoop = false;
                                "
                                @blur="setFace(6)"
                            />
                            <img src="/media/svg/profile.svg" />
                        </div>
                        <div class="form-input-group w-100">
                            <EKInputText
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'رقم الجوال مطلوب'
                                    },
                                    { type: 'mobile', message: 'رقم غير صحيح' }
                                ]"
                                v-model="userDto.phoneNumber"
                                placeholder="رقم الجوال (09xxxxxxxx)"
                                name="mobile"
                                @focus="
                                    setFace(14);
                                    isLoop = false;
                                "
                                @blur="setFace(6)"
                            />
                            <img src="/media/svg/call.svg" />
                        </div>
                        <div class="form-input-group select w-100">
                            <EKInputSelect
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'العنوان مطلوب'
                                    }
                                ]"
                                v-model="userDto.areaId"
                                :options="usersAddresses"
                                placeholder="العنوان"
                                pushTags
                                taggable
                                clearable
                                :createOption="e => ({ id: e, name: e })"
                                name="area"
                                @focus="
                                    setFace(11);
                                    isLoop = false;
                                "
                                @blur="setFace(6)"
                            />
                            <img src="/media/svg/house.svg" />
                        </div>
                        <div class="d-flex">
                            <b-button
                                @click="submitUser()"
                                variant="primary"
                                class="submit-btn px-5 py-2 mt-2 mr-1"
                                >إدخال</b-button
                            >
                            <b-button
                                @click="
                                    returnBack();
                                    backFace(8, 6, 1000);
                                "
                                @mouseenter="
                                    setFace(9);
                                    isLoop = false;
                                "
                                @mouseleave="backFace(8, 6, 1000)"
                                variant="outline-primary"
                                class="submit-btn px-5 py-2 mt-2"
                                >عودة</b-button
                            >
                        </div>
                    </div>
                </ValidationObserver>
            </b-col>
        </b-row>
        <div class="slide-touch bg-primary text-white">
            اسحب للبدء
            <unicon
                class="slide-arrow"
                name="arrow-left"
                fill="white"
                width="28"
            />
        </div>
    </div>
</template>

<script>
import EKInputText from "@/EK-core/components/EK-forms/EK-input-text";
import EKInputSelect from "@/EK-core/components/EK-forms/EK-input-select";
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import Swal from "sweetalert2";
import { toast } from "@/libs/vue-toast.js";
import { nullGuid } from "@/EK-core/util/global";
export default {
    components: {
        EKInputText,
        EKInputSelect,
        ValidationObserver,
        LottieAnimation
    },
    data: () => ({
        xDown: null,
        yDown: null,
        isActive: false,
        isFormActive: false,
        wink: null,
        mouth: null,
        inputAnim: 6,
        isLoop: true,
        nullGuid
    }),
    created() {
        document.addEventListener("touchstart", this.handleTouchStart, false);
        document.addEventListener("touchmove", this.handleTouchMove, false);
        document.addEventListener("mousedown", this.handleClickStart, false);
        document.addEventListener("mousemove", this.handleClickMove, false);
        this.getAddresses();
    },
    computed: {
        ...mapState({
            userDto: state => state.store.userDto,
            usersAddresses: state => state.store.usersAddresses
        })
    },
    methods: {
        ...mapActions(["addUser", "getAddresses"]),
        getTouches(evt) {
            return evt.touches || evt.originalEvent.touches;
        },
        setFace(i) {
            this.inputAnim = i;
        },
        backFace(start, end, t = 0) {
            this.setFace(start);
            setTimeout(() => {
                this.setFace(end);
                this.isLoop = true;
            }, t);
        },
        handleTouchStart(e) {
            const firstTouch = this.getTouches(e)[0];
            this.xDown = firstTouch.clientX;
            this.yDown = firstTouch.clientY;
        },
        handleTouchMove(evt) {
            if (!this.xDown || !this.yDown) {
                return;
            }
            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;

            var xDiff = this.xDown - xUp;
            var yDiff = this.yDown - yUp;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0) {
                    this.isActive = true;
                    setTimeout(() => {
                        this.isFormActive = true;
                    }, 400);
                    removeEventListener(
                        "touchstart",
                        this.handleTouchStart,
                        false
                    );
                    removeEventListener(
                        "touchmove",
                        this.handleTouchMove,
                        false
                    );
                }
            }
            this.xDown = null;
            this.yDown = null;
        },
        handleClickStart(e) {
            this.xDown = e.clientX;
            this.yDown = e.clientY;
        },
        handleClickMove(evt) {
            if (!this.xDown || !this.yDown) {
                return;
            }
            var xUp = evt.clientX;
            var yUp = evt.clientY;

            var xDiff = this.xDown - xUp;
            var yDiff = this.yDown - yUp;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0) {
                    this.isActive = true;
                    setTimeout(() => {
                        this.isFormActive = true;
                    }, 200);
                    document.addEventListener(
                        "mousedown",
                        this.handleClickStart,
                        false
                    );
                    document.addEventListener(
                        "mousemove",
                        this.handleClickMove,
                        false
                    );
                }
            }
            this.xDown = null;
            this.yDown = null;
        },
        returnBack() {
            document.addEventListener(
                "touchstart",
                this.handleTouchStart,
                false
            );
            document.addEventListener("touchmove", this.handleTouchMove, false);
            document.addEventListener(
                "mousedown",
                this.handleClickStart,
                false
            );
            document.addEventListener("mousemove", this.handleClickMove, false);
            this.$store.commit("RESET_FORM");
            this.isFormActive = false;
            setTimeout(() => {
                this.isActive = false;
            }, 200);
        },
        submitUser() {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    if (
                        !this.usersAddresses.find(
                            a => a.id == this.userDto.areaId
                        )
                    ) {
                        this.userDto.area = this.userDto.areaId;
                        this.userDto.areaId = null;
                    }
                    this.addUser(this.userDto)
                        .then(({ data }) => {
                            if (data.isSuccess) {
                                Swal.fire({
                                    title:
                                        "نحن في ارينا مول نسعى لتلبية جميع احتياجاتكم في مكان واحد",
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 4000
                                });
                                if(!this.userDto.areaId) {
                                    this.$store.commit("Add_User", {
                                        id: data.result.areaId,
                                        name: data.result.area
                                    });
                                }
                                this.$refs.observer.reset();
                                this.returnBack();
                            } else {
                                toast.open({
                                    message: "الرجاء إعادة المحاولة رقم الهاتف غير مقبول",
                                    type: "error",
                                    duration: 2000,
                                    dismissible: true
                                });
                            }
                        })
                        .catch(() => {
                            toast.open({
                                message:
                                    "الرجاء إعادة المحاولة رقم الهاتف موجود مسبقا او حدث عطل غير متوقع",
                                type: "error",
                                duration: 2000,
                                dismissible: true
                            });
                        });
                }
            });
        }
    },
    beforeDestroy() {
        removeEventListener("touchstart", this.handleTouchStart, false);
        removeEventListener("touchmove", this.handleTouchMove, false);
        removeEventListener("mousedown", this.handleClickStart, false);
        removeEventListener("mousemove", this.handleClickMove, false);
        this.$store.commit("RESET_FORM");
    }
};
</script>

<style scoped lang="scss">
.full-app {
    position: relative;
    background: #f1f7f7;
    overflow-x: hidden;
    .corner {
        position: absolute;
        width: 132px;
        height: 132px;
        border-radius: 50%;
        transform: rotate(-180deg);
        filter: blur(4px);
        transition: all 1.7s cubic-bezier(0.19, 1, 0.22, 1);
        z-index: 0;
    }
    .blue-circle {
        background: linear-gradient(180deg, #7a499b 0%, #43b7e8 100%);
        right: -40px;
        top: calc(100vh - 172px);
    }
    .gradiant-circle {
        top: -40px;
        right: calc(100vw - 72px);
        background: linear-gradient(180deg, #f8ec1f 0%, #68308e 100%);
    }
    .robot {
        padding: 50px 0;
        height: 100vh;
        min-height: 500px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        position: relative;
        transition: all 1s linear;
        z-index: 1;
        .logo {
            user-select: none;
            max-width: 260px;
        }
        .face {
            transition: all 0.5s linear;
            max-width: 500px;
            width: 100%;
            height: 260px;
            background: transparent;
            box-shadow: 0px 4px 15px transparent;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
        }
        p {
            max-width: 500px;
            text-align: center;
            user-select: none;
        }
    }
    .slide-touch {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 18px;
        padding: 10px;
        text-align: center;
    }
    .form {
        max-width: 500px;
        min-height: 600px;
        height: 100vh;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form-input-group {
            position: relative;
            ::v-deep {
                .ek-form-group {
                    .form-control {
                        background: linear-gradient(
                            180deg,
                            #e7f4f9 0%,
                            rgba(251, 249, 243, 0) 100%
                        );
                        backdrop-filter: blur(4px);
                        border-radius: 20px;
                        height: 60px;
                        border-bottom: none;
                        border-color: #43b7e8;
                        border-top-width: 2px;
                        padding-left: 40px;
                    }
                }
                .v-select {
                    border: none;
                    .vs__dropdown-toggle {
                        background: linear-gradient(
                            180deg,
                            #e7f4f9 0%,
                            rgba(251, 249, 243, 0) 100%
                        );
                        backdrop-filter: blur(4px);
                        border-radius: 20px;
                        height: 60px;
                        border: 1px solid #43b7e8 !important;
                        border-bottom: none !important;
                        border-top-width: 2px;
                        padding-left: 40px;
                    }
                }
            }
            img {
                position: absolute;
                top: 36px;
                left: 16px;
            }
        }
        .select {
            img {
                top: 20px;
            }
        }
        .submit-btn {
            border-radius: 20px;
        }
    }
    &.active {
        background: transparent;
        .corner {
            transform: rotate(180deg);
        }
        .blue-circle {
            top: -40px;
            right: calc(100vw - 72px);
        }
        .gradiant-circle {
            right: -40px;
            top: calc(100vh - 172px);
        }
        .robot {
            padding: 0;
            .face {
                background: #f1f7f7;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
                border-radius: 20px;
            }
        }
        .slide-touch {
            opacity: 0;
        }
    }
    .robot-container {
        transition: all 1.7s cubic-bezier(0.19, 1, 0.22, 1);
    }
}
.slide-arrow {
    animation: swipe-head 3s infinite ease-in-out;
}
@keyframes swipe-head {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(30px);
    }
    100% {
        transform: translateX(0);
    }
}
</style>
