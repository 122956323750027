var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-app",class:{ active: _vm.isActive }},[_c('div',{staticClass:"blue-circle corner"}),_c('div',{staticClass:"gradiant-circle corner"}),_c('b-row',[_c('b-col',{staticClass:"robot-container",attrs:{"cols":12,"md":_vm.isActive ? 6 : 12}},[_c('div',{staticClass:"robot p-1"},[_c('img',{staticClass:"logo",attrs:{"src":"/media/logo.svg","alt":"Arena"}}),_c('div',{staticClass:"face"},[_c('LottieAnimation',{staticClass:"lottie",attrs:{"loop":_vm.isLoop,"path":'./media/lottie/smile' + _vm.inputAnim + '.json'}})],1),_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(" زيارة وحدة مابتكفي ")])])])]),(_vm.isFormActive)?_c('b-col',{staticClass:"animate__animated animate__jackInTheBox",attrs:{"cols":"","md":"6"}},[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"form px-3"},[_c('h4',{staticClass:"mb-3"},[_c('strong',[_vm._v("قم بتسجيل بياناتك داخل الحقول")])]),_c('div',{staticClass:"form-input-group w-100"},[_c('EKInputText',{attrs:{"placeholder":"الاسم الكامل (إختياري)","name":"fullname"},on:{"focus":function($event){_vm.setFace(13);
                                _vm.isLoop = false;},"blur":function($event){return _vm.setFace(6)}},model:{value:(_vm.userDto.name),callback:function ($$v) {_vm.$set(_vm.userDto, "name", $$v)},expression:"userDto.name"}}),_c('img',{attrs:{"src":"/media/svg/profile.svg"}})],1),_c('div',{staticClass:"form-input-group w-100"},[_c('EKInputText',{attrs:{"rules":[
                                {
                                    type: 'required',
                                    message: 'رقم الجوال مطلوب'
                                },
                                { type: 'mobile', message: 'رقم غير صحيح' }
                            ],"placeholder":"رقم الجوال (09xxxxxxxx)","name":"mobile"},on:{"focus":function($event){_vm.setFace(14);
                                _vm.isLoop = false;},"blur":function($event){return _vm.setFace(6)}},model:{value:(_vm.userDto.phoneNumber),callback:function ($$v) {_vm.$set(_vm.userDto, "phoneNumber", $$v)},expression:"userDto.phoneNumber"}}),_c('img',{attrs:{"src":"/media/svg/call.svg"}})],1),_c('div',{staticClass:"form-input-group select w-100"},[_c('EKInputSelect',{attrs:{"rules":[
                                {
                                    type: 'required',
                                    message: 'العنوان مطلوب'
                                }
                            ],"options":_vm.usersAddresses,"placeholder":"العنوان","pushTags":"","taggable":"","clearable":"","createOption":function (e) { return ({ id: e, name: e }); },"name":"area"},on:{"focus":function($event){_vm.setFace(11);
                                _vm.isLoop = false;},"blur":function($event){return _vm.setFace(6)}},model:{value:(_vm.userDto.areaId),callback:function ($$v) {_vm.$set(_vm.userDto, "areaId", $$v)},expression:"userDto.areaId"}}),_c('img',{attrs:{"src":"/media/svg/house.svg"}})],1),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"submit-btn px-5 py-2 mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submitUser()}}},[_vm._v("إدخال")]),_c('b-button',{staticClass:"submit-btn px-5 py-2 mt-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.returnBack();
                                _vm.backFace(8, 6, 1000);},"mouseenter":function($event){_vm.setFace(9);
                                _vm.isLoop = false;},"mouseleave":function($event){return _vm.backFace(8, 6, 1000)}}},[_vm._v("عودة")])],1)])])],1):_vm._e()],1),_c('div',{staticClass:"slide-touch bg-primary text-white"},[_vm._v(" اسحب للبدء "),_c('unicon',{staticClass:"slide-arrow",attrs:{"name":"arrow-left","fill":"white","width":"28"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }